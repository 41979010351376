import styled from "styled-components";

export const SectionDiv = styled.div`
padding-top: 5%;
position: relative;
width: 100%;
display: flex;
flex-direction: column;
justify-content: flex-start;
align-items: center;
`